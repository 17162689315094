import axios from 'axios';

const http = axios.create({
    baseURL: '/openai',
    headers: {'Content-Type': 'application/json'},
    timeout: 60 * 1000
})
// 添加请求拦截器
http.interceptors.request.use(config => {
    return config
}, error => {
    // 请求错误处理
    return Promise.reject(error)
})

// 添加响应拦截器
http.interceptors.response.use((response) => {
    return response;
}, function (error) {
    // 响应错误处理
    return Promise.reject(error);
});


/**
 * 创建统一封装过的 axios 实例
 * @return {AxiosInstance}
 */
export default function () {
    return http
}
